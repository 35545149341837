@use '../../assets/scss/utils' as *;
.available_for{
   position: relative;
    font-size: 38px !important;
    & span{
        font-family: 'Poppins';
        font-weight: normal;
        visibility: hidden;
        position:absolute;
        top: 46px;
        left:0;
        font-size: 12px;
        line-height: 1.5;
      
       background-color: #555;
       color: #fff;
       padding: 8px;
       border-radius: 8px;
      
      
    }

    & span::after{
        content: "";
        position: absolute;
        bottom: 99%;
        left: 18px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color:  transparent transparent  #555 transparent;
      }
 
}
.available_for:hover{
    animation: hoverme 0.25s linear;
    animation-fill-mode: forwards;
    & span{
        visibility: visible;
    }
}

.available_for1{
    font-size: 44px !important;
    & span {
        top:50px
    }
}


@keyframes hoverme {
    from {
        transform: scale(1);
      }
      to {
        transform: scale(1.3);
      }

}




