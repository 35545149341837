@use '../utils' as *;

/*----------------------------------------*/
/*  3. about CSS START
/*----------------------------------------*/
.about {
    &__shape-1 {
        position: absolute;
        right: 8%;
        top: -17%;
        animation: rotate2 15s linear infinite;

        @media #{$md} {
            right: 5%;
            top: -13%;
        }

        @media #{$xs} {
            right: 2%;
            top: -11%;
            z-index: -1;
        }
    }

    &__shape-2 {
        position: absolute;
        right: 5%;
        bottom: 15%;
        z-index: -2;
        animation: moving 9s linear infinite;

        @media #{$xl} {
            bottom: 2%;
        }
    }

    &__content {
        & p {
            padding-bottom: 10px;

            @media #{$xs} {
                font-size: 15px;
            }
        }
    }

    &__border-right {
        border-right: 1px solid var(--it-border-1);

        @media #{$xs} {
            border-right: 0;
        }
    }

    &__list-item {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--it-border-1);

        & ul {
            display: inline-block;

            @media #{$xs} {
                margin-bottom: 20px;
            }

            & li {
                list-style-type: none;
                position: relative;
                padding-left: 25px;
                padding-bottom: 20px;
                width: 50%;
                float: left;

                @media #{$xs} {
                    width: 100%;
                }

                @media #{$sm} {
                    width: 50%;
                }

                &:hover {
                    & span {
                        text-decoration: underline;
                    }
                }

                & a {
                    transition: .3s;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                & i {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    height: 15px;
                    width: 15px;
                    border: 1.5px solid var(--it-theme-1);
                    line-height: 12px;
                    text-align: center;
                    display: inline-block;
                    font-size: 12px;
                    color: var(--it-theme-1);
                }
            }
        }
    }

    &__img {
        @media #{$md} {
            text-align: center;
        }
        @media #{$xs} {
            text-align: center;
        }
        & img {
            max-width: inherit;
            margin-left: -80px;

            @media #{$xl} {
                max-width: 100%;
                margin-left: -20px;
            }

            @media #{$lg} {
                max-width: 100%;
                margin-left: -10px;
            }

            @media #{$md} {
                margin-bottom: 70px;
                margin-left: 0;
            }

            @media #{$xs} {
                max-width: 100%;
                margin-left: 0;
                margin-bottom: 50px;
            }
        }
    }

    &__contact-box {
        padding-top: 30px;
    }

    &__contact-icon {
        & span {
            margin-right: 15px;
            width: 55px;
            height: 55px;
            background-color: var(--it-theme-1);
            border-radius: 50%;
            line-height: 64px;
            text-align: center;
            display: inline-block;

            & i {
                font-size: 25px;
                color: var(--it-common-white);
            }
        }
    }

    &__contact-number {
        & a {
            font-weight: 500;
            color: var(--it-common-black);
            font-size: 18px;
            display: block;

            &:hover {
                color: var(--it-common-paste);
            }

            @media #{$lg} {
                font-size: 16px;
            }
        }
    }

    &__video {
        padding-top: 35px;
    }

    &__video-icon {
        & a {
            margin-right: 35px;
            width: 45px;
            height: 45px;
            background-color: var(--it-theme-1);
            border-radius: 50%;
            line-height: 46px;
            text-align: center;
            display: inline-block;
            position: relative;
            z-index: 1;
            &::after{
                content: '';
                left: 0;
                top: 0;
                position: absolute;
                background-color:rgba(255, 221, 96, 0.339);
                width: 100%;
                height: 100%;
                border-radius: 50%;
                transform: scale(1.3);
                z-index: -1;

            }

            & i {
                font-size: 15px;
                color: var(--it-common-white);
                margin-left: 4px;
            }
        }
    }

    &__video-text {
        & a {
            font-weight: 500;
            font-size: 18px;
            color: var(--it-common-black);

            &:hover {
                color: var(--it-common-paste);
            }

            @media #{$lg} {
                font-size: 16px;
            }
        }
    }
}

.about-2 {
    &__shape-1 {
        position: absolute;
        top: 11%;
        right: 12%;
        animation: rotate2 15s linear infinite;

        @media #{$xxxl,$xxl} {
            right: 3%;
        }

        @media #{$xl} {
            top: 4%;
            right: 1%;
        }
    }

    &__shape-2 {
        position: absolute;
        top: 67%;
        left: 12%;
        animation: jumpTwo 4s infinite linear;

        @media #{$xxxl} {
            left: 5%;
        }

        @media #{$xxl} {
            left: 2%;
        }

        @media #{$xl} {
            top: 66%;
            left: 1%;
        }
    }

    &__shape-3 {
        position: absolute;
        bottom: 19%;
        right: 5%;
        animation: moving 9s linear infinite;

        @media #{$xxl} {
            right: 0;
        }

        @media #{$xl} {
            right: 1%;
            bottom: 2%;
        }
    }

    &__section-title {
        padding-bottom: 5px;
    }

    &__main-img {
        & img {
            max-width: inherit;
            transform: translateX(-102px);

            @media #{$xl} {
                max-width: 100%;
                transform: translateX(-15px);
            }

            @media #{$lg} {
                max-width: 100%;
                transform: translateX(-18px);
            }

            @media #{$md,$xs} {
                max-width: 100%;
                transform: translateX(0);
                margin-bottom: 70px;
            }
        }
    }

    &__main-img-content {
        background-color: var(--it-theme-1);
        border-radius: 50px;
        position: absolute;
        top: 7%;
        right: 2%;
        padding: 12px 20px;
        padding-right: 40px;

        @media #{$xl} {
            top: 4%;
        }

        @media #{$lg} {
            top: 4%;
            right: 2%;
            padding-right: 20px;
            padding: 2px 12px;
        }

        @media #{$xs} {
            top: 68%;
            right: 18%;
            padding: 2px 10px;
            padding-right: 20px;
        }

        @media #{$sm} {
            top: 7%;
            right: -2%;
            padding: 10px 15px;
            padding-right: 30px;
        }

    }

    &__icon {
        & span {
            font-weight: 500;
            color: var(--it-common-black);
            font-size: 42px;
            padding-right: 25px;

            @media #{$lg} {
                padding-right: 10px;
            }

            @media #{$xs} {
                font-size: 34px;
                padding-right: 5px;
            }

            @media #{$sm} {
                font-size: 37px;
                padding-right: 15px;
            }
        }
    }

    &__img-text {
        & h5 {
            font-weight: 700;
            font-size: 27px;
            margin-bottom: 0;

            @media #{$lg} {
                font-size: 18px;
            }

            @media #{$xs} {
                font-size: 18px;
            }

            @media #{$sm} {
                font-size: 22px;
            }
        }

        & span {
            font-weight: 500;
            color: #3f3f3f;

            @media #{$lg} {
                font-size: 13px;
            }

            @media #{$xs} {
                font-size: 12px;
            }

            @media #{$sm} {
                font-size: 14px;
            }
        }
    }

    &__text {
        padding-bottom: 10px;

        & p {
            padding-right: 115px;

            @media #{$lg,$md} {
                padding-right: 0;
            }

            @media #{$xs} {
                padding-right: 0;
                font-size: 15px;
            }
        }
    }

    &__list-title {
        font-weight: 500;
        font-size: 20px;
        font-family: 'Rubik', sans-serif;
        background-color: var(--it-theme-1);
        padding: 11px 23px;
        display: inline-block;
        margin-bottom: 0;
    }

    &__list-item {
        margin-right: 100px;
        padding-bottom: 44px;

        @media #{$lg,$md,$xs} {
            margin-right: 0;
        }

        & ul {
            padding: 30px;
            background-color: #f3f3f3;
            display: inline-block;

            & li {
                width: 50%;
                float: left;
                list-style: none;
                padding-bottom: 15px;

                @media #{$xs} {
                    width: 100%;
                }

                &:nth-child(2n+1) {
                    padding-bottom: 0;

                    @media #{$xs} {
                        padding-bottom: 15px;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }

                & span {
                    font-weight: 400;
                    font-size: 17px;

                    &::before {
                        content: '';
                        display: inline-block;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        background-color: var(--it-theme-1);
                        border: 1px solid var(--it-common-black);
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

.about-3 {
    &__plr {
        padding: 0 200px;
        @media #{$xxxl,$xxl} {
            padding: 0 100px;
        }
        @media #{$xl,$lg,$md} {
            padding: 0 30px;
        }
        @media #{$xs} {
            padding: 0;
        }
    }

    &__space {
        padding-bottom: 50px;

        @media #{$xs} {
            padding-bottom: 0;
        }
    }

    &__content {
        padding-right: 206px;

        @media #{$xxxl} {
            padding-right: 79px;
        }

        @media #{$xxl} {
            padding-right: 57px;
        }

        @media #{$xl} {
            padding-right: 29px;
        }

        @media #{$lg} {
            padding-right: 0;
        }

        @media #{$xs} {
            padding-right: 0;
        }
    }

    &__content-list {
        padding-bottom: 5px;
        border-bottom: 1px solid var(--it-border-1);

        & ul {
            display: inline-block;
            padding-bottom: 5px;

            & li {
                list-style-type: none;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;
                width: 50%;
                float: left;

                @media #{$xs} {
                    width: 100%;
                }

                @media #{$sm} {
                    width: 50%;
                }

                & i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    font-weight: 500;
                    color: var(--it-common-blue-3);
                    font-size: 16px;
                }

                & span {
                    font-weight: 400;
                    font-size: 17px;

                    @media #{$lg,$xs} {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &__video {
        padding-right: 30px;

        & a {
            height: 52px;
            width: 52px;
            border-radius: 50%;
            background-color: var(--it-theme-1);
            line-height: 52px;
            display: inline-block;
            text-align: center;

            & i {
                font-size: 16px;
                color: var(--it-common-white);
                padding-left: 4px;
            }
        }
    }

    &__video-text {
        & span {
            font-size: 18px;
            font-weight: 500;
            color: var(--it-common-black);
            text-decoration: underline;
            transition: .3s;
            &:hover{
                color: var(--it-common-blue-3);
            }
        }
    }

    &__button-box {
        padding-top: 30px;

        @media #{$xxl} {
            padding-top: 0;
        }
        @media #{$xs} {
            flex-wrap: wrap;
        }
    }

    &__item {
        & img {
            max-width: inherit;
            transform: translateX(20px);
            animation: tpupdown 0.8s infinite alternate;

            @media #{$xxxl} {
                max-width: 100%;
                transform: translateX(-30px);
            }

            @media #{$xxl} {
                max-width: 100%;
                transform: translateX(-30px);
            }

            @media #{$xl} {
                max-width: 100%;
                transform: translateX(-40px);
            }

            @media #{$lg} {
                max-width: 100%;
                transform: translateX(-30px);
            }

            @media #{$md} {
                max-width: 100%;
                transform: translateX(-2px);
            }

            @media #{$xs} {
                max-width: 100%;
                transform: translateX(-2px);
            }
        }
    }

    &__text {
        & p {
            padding-right: 20px;

            @media #{$lg,$xs} {
                padding-right: 0;
                font-size: 14px;
            }
        }
    }

    &__button {
        @media #{$xs} {
            margin-bottom: 30px;
        }
    }

    &__content-list-2 {
        border: none;
        & ul {
            & li {
                width: 100%;
                transform: .3s;                
                text-decoration: transparent;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    &__plr-2{
        padding-top: 120px;
    }
    &__item-img{
        & img{
            max-width:100%;
        }
    }
}
.about-3{
    &__button-wrapper{
        position: absolute;
        left: 4%;
        bottom: 6%;
        background-color: var(--it-common-white);
        padding: 20px 41px;
        border-radius: 20px;
        margin-bottom: 0;
        @media #{$xs} {
            padding: 15px 15px;
            left: 4%;
            bottom: 7%;
            background-color: var(--it-common-white);
            padding: 6px 12px;
        }
        & .about-3__video{
            @media #{$xs} {
                padding-right: 10px;
            }
            & a{
                @media #{$xs} {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    background-color: var(--it-theme-1);
                    line-height: 35px;
                }
                & i{
                    @media #{$xs} {
                        font-size: 13px;
                    }
                }
            }
        }
        & .about-3__video-text{
            & span{                
                @media #{$xs} {
                    font-size: 16px;
                }                
            }
        }
    }
}
.about-3{
    &__wrapper-item{
        border-radius: 20px;
        border: 1px solid var(--it-common-white-2);
        padding: 65px 80px;
        @media #{$xxxl} {
            padding: 61px 60px;
        }
        @media #{$xxl} {
            padding: 25px 30px;
            & .section-title{
                & br{
                    display: none;
                }
            }
        }
        @media #{$xl} {
            padding: 33px 21px;
        }
        @media #{$lg} {
            padding: 15px 22px;
        }
        @media #{$md} {
            padding: 33px 40px;
        }
        @media #{$xs} {
            padding: 30px 15px;
        }
        @media #{$sm} {
            padding:35px 20px;
        }
        & .about-3__content{
            padding-right: 50px;
            @media #{$xxl} {
                padding: 0;
            }
            @media #{$xl} {
                padding: 0;
            }
            @media #{$lg} {
                padding: 0;
            }
            @media #{$md} {
                padding: 0;
            }
            @media #{$md,$xs} {
                padding: 0;
            }
        }
        & .section-title{
            @media #{$xl} {
                font-size: 34px;
                & br{
                    display: none;
                }
            }
        }
        & .about-3__button{
            padding-top: 30px;
            margin-bottom: 0;
            @media #{$xl,$lg} {
                padding-top: 0;
            }
            @media #{$xs} {
                padding-top: 0;
            }
        }
        & .main-btn-sm{
            @media #{$lg} {
                height: 35px;
                line-height: 35px;
                padding: 0 20px;
            }
        }
        &__text{
            & p{
                @media #{$md} {
                    padding-right: 0;
                }
            }
        }
    }
}


.about-4 {
    &__plr {
        padding: 0 200px;
        padding-top: 120px;
        padding-bottom: 120px;

        @media #{$xxxl} {
            padding: 0 100px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$xxl} {
            padding: 0 100px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$xl} {
            padding: 0 30px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$lg} {
            padding: 0 30px;
            padding-top: 0;
            padding-bottom: 120px;
        }

        @media #{$md} {
            padding: 0 30px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$xs} {
            padding: 0;
            padding-top: 0;
            padding-bottom: 120px;
        }
    }

    &__bg {
        background-color: #31303f;
        border-radius: 10px;
        padding: 90px 100px;
        position: relative;
        z-index: 2;

        @media #{$xxl} {
            padding: 90px 60px;
        }

        @media #{$lg} {
            padding: 60px 10px;
        }

        @media #{$md} {
            padding: 90px 20px;
        }

        @media #{$xs} {
            padding: 90px 0;
        }
    }

    &__main-img {
        animation: tptranslateY2 4s infinite alternate;

        @media #{$md} {
            margin-bottom: 100px;
        }

        @media #{$xs} {
            margin-bottom: 70px;
        }

        & img {
            margin-top: -211px;

            @media #{$lg} {
                margin-top: 0;
            }

            @media #{$xs} {
                margin-top: 0;
                width: 100%;
            }
        }
    }

    &__text {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--it-border-2);

        & p {
            color: var(--it-common-white-2);
            @media #{$xs} {
                font-size: 15px;
            }
        }
    }

    &__content-list {
        padding-bottom: 5px;
        margin-top: 35px;
        margin-bottom: 30px;

        & ul {
            padding-bottom: 5px;

            & li {
                list-style-type: none;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;

                & i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    font-weight: 500;
                    color: #83adff;
                    font-size: 16px;
                }

                & span {
                    color: var(--it-common-white-2);
                    font-weight: 400;
                    font-size: 17px;
                    text-decoration: transparent;
                    transition: .3s;

                    &:hover {
                        text-decoration: underline;
                    }

                    @media #{$lg,$xs} {
                        font-size: 15px;
                    }
                }
            }
        }
    }

    &__video {
        padding-right: 40px;

        & span {
            height: 47px;
            width: 47px;
            border-radius: 50%;
            background-color: var(--it-theme-1);
            line-height: 49px;
            display: inline-block;
            text-align: center;
            position: relative;
            z-index: 2;

            &::after {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                background-color: rgba(243, 215, 111, 0.588);
                position: absolute;
                content: "";
                left:0;
                top: 0;
                z-index: -1;
                transform: scale(1.3);
            }

            & i {
                font-size: 16px;
                color: var(--it-common-black);
                padding-left: 4px;
            }
        }
    }

    &__video-text {
        & span {
            font-size: 18px;
            font-weight: 500;
            color: var(--it-common-white);
            text-decoration: underline;
            transition: .3s;
            &:hover{
                color: var(--it-theme-1);
            }
        }
    }

    &__shape-1 {
        position: absolute;
        top: 18%;
        left: 12%;
        z-index: 3;
        animation: tptranslateX2 4s infinite alternate;

        @media #{$xxxl} {
            top: 17%;
            left: 8%;
        }

        @media #{$xxl} {
            top: 17%;
            left: 9%;
        }

        @media #{$xl} {
            top: 16%;
            left: 5%;
        }
    }

    &__shape-2 {
        position: absolute;
        top: 27%;
        left: 40%;
        z-index: 3;
        animation: zoom 7s infinite;

        @media #{$xxxl} {
            top: 29%;
            left: 37%;
        }

        @media #{$xxl} {
            top: 32%;
            left: 39%;
        }
    }

    &__shape-3 {
        position: absolute;
        bottom: 19%;
        left: 41%;
        z-index: 3;
        animation: tpupdown 0.8s infinite alternate;

        @media #{$xxl} {
            bottom: 15%;
        }
    }

    &__shape-4 {
        position: absolute;
        bottom: 8%;
        left: 7%;
        z-index: 1;
        animation: rotate2 15s linear infinite;

        @media #{$xxxl} {
            left: 2%;
        }

        @media #{$xxl} {
            left: 2%;
        }

        @media #{$xl} {
            bottom: 4%;
            left: 0%;
        }

        @media #{$lg} {
            bottom: 3%;
            left: 1%;
        }
    }

    &__shape-5 {
        animation: moving 9s linear infinite;
        position: absolute;
        top: 1%;
        right: 2%;

        @media #{$xxxl} {
            top: -5%;
        }

        @media #{$xxl} {
            top: -6%;
        }

        @media #{$xl} {
            top: -5%;
        }

        @media #{$lg} {
            top: -7%;
            right: 1%;
        }
    }
}

.about-4 {
    &__section-title {
        @media #{$xl} {
            & .section-title {
                & br {
                    display: none;
                }
            }

        }
    }
}
.about-5 {
    &__plr {
        padding: 0 200px;
        padding-top: 120px;
        padding-bottom: 120px;

        @media #{$xxxl} {
            padding: 0 100px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$xxl} {
            padding: 0 100px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$xl} {
            padding: 0 30px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$lg} {
            padding: 0 30px;
            padding-top: 0;
            padding-bottom: 120px;
        }

        @media #{$md} {
            padding: 0 30px;
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @media #{$xs} {
            padding: 0;
            padding-top: 0;
            padding-bottom: 120px;
        }
    }
    &__text {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--it-border-1);
        & p {
            @media #{$xs} {
                font-size: 13px;
            }
            @media #{$sm} {
                font-size: 15px;
            }
        }
    }
    &__content-list {
        padding-bottom: 40px;
        padding-top: 25px;
        @media #{$lg} {
            padding-bottom:0;
        }
        @media #{$xs} {
            padding-bottom:10px;
        }
        & ul {
            display: inline-block;
            padding-bottom: 5px;

            & li {
                list-style-type: none;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;
                width: 50%;
                float: left;

                @media #{$xs} {
                    width: 100%;
                }

                @media #{$sm} {
                    width: 50%;
                }

                & i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    font-weight: 500;
                    color: var(--it-common-blue-3);
                    font-size: 16px;
                }

                & span {
                    font-weight: 400;
                    font-size: 17px;

                    @media #{$lg,$xs} {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    &__content-title{
        font-size: 24px;
        padding-bottom: 20px;
        @media #{$lg} {
            padding-bottom:0;
        }
        @media #{$xs} {
            font-size: 18px;
            padding-bottom:0;
        }
    }
    &__border{
        border: 1px solid var(--it-border-1);
        border-radius: 10px;
        padding: 40px;
        @media #{$md} {
            padding: 43px 40px;
            margin: 0 42px;
        }
        @media #{$xs} {
            padding: 15px;
        }
        @media #{$sm} {
            padding: 30px 15px;
        }
    }
    &__content-box {
        margin-right: 302px;
        margin-left: -40px;
        @media #{$xxxl} {
            margin-right: 203px;
            margin-left: 0;
        }
        @media #{$xxl} {
            margin-right: 56px;
            margin-left: 30px;
        }
        @media #{$xl} {
            margin-right: 55px;
            margin-left: 20px;
        }
        @media #{$lg} {
            margin-right:0;
            margin-left: 20px;
        }
        @media #{$md} {
            margin-right: 0;
            margin-left: 0;
        }
        @media #{$xs} {
            margin-right: 0;
            margin-left: 0;
        }
    }
    &__main-img{        
        @media #{$md} {
            margin-bottom: 50px;
        }
        @media #{$xs} {
            margin-bottom: 30px;
        }
    }
}
.about-details{
    &__plr {
        padding: 0 200px;        
        border-bottom: 1px solid var(--it-border-1);           
        @media #{$xxxl,$xxl} {
            padding: 0 100px;
        }    
        @media #{$xl,$lg,$md} {
            padding: 0 30px;
        }    
        @media #{$xs} {
            padding: 0;
        }
    }
    &__content-list {
        padding:10px 0;
        & ul {
            padding-bottom: 5px;
            & li {
                list-style-type: none;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;
                text-decoration: transparent;
                transition: .3s;
                @media #{$xs} {
                    width: 100%;
                }

                @media #{$sm} {
                    width: 50%;
                }
                &:hover{
                    text-decoration: underline;
                }

                & i {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    font-weight: 500;
                    color: var(--it-common-blue-3);
                    font-size: 16px;
                }

                & span {
                    font-weight: 400;
                    font-size: 17px;

                    @media #{$lg} {
                        font-size: 15px;
                    }
                    @media #{$xs} {
                        font-size: 14px;
                    }
                    @media #{$sm} {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &__title{
        font-size: 24px;
        padding-bottom: 20px;
        @media #{$xxl,$xl} {
            padding-bottom: 10px;
        }
        @media #{$lg} {
            padding-bottom:0;
        }
        @media #{$xs} {
            font-size: 18px;
            padding-bottom: 0;
        }

    }
    &__content {
        padding-left: 25px;
        padding-right: 190px;
        @media #{$xxxl} {
            padding-right: 90px;
        }
        @media #{$xxl} {
            padding-right: 50px;
        }
        @media #{$xl} {
            padding-right: 20px;
        }
        @media #{$lg} {
            padding-left: 10px;
            padding-right: 0;
        }
        @media #{$md} {
            padding-left: 0;
            padding-right: 0;
        }
        @media #{$xs} {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__text{
        & p {
            @media #{$lg,$md} {
                font-size: 15px;
    
            }
            @media #{$xs} {
                font-size: 13px;
    
            }
            @media #{$sm} {
                font-size: 15px;
    
            }
        }
    }
    &__text-2{
        font-size: 17px;
        padding-bottom: 75px;
        @media #{$xs} {
            padding-bottom: 40px;            
        }
        & p {
            @media #{$xxl,$xl} {
                & br{
                    display: none;
                }
            }
            @media #{$lg,$md} {
                font-size: 15px;
                & br{
                    display: none;
                }
    
            }
            @media #{$xs} {
                font-size: 13px;
                & br{
                    display: none;
                }
    
            }
            @media #{$sm} {
                font-size: 15px;
    
            }
        }
    }
    &__main-img{
        @media #{$md} {
            margin-bottom: 50px;
        }
        @media #{$xs} {
            margin-bottom: 30px;
        }
    }
}
