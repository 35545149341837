.screenInfo{
    position:absolute;
    color:white;
    z-index: 100;
    top:0px;
    background-color: rgba(0,0,0,0.5);
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

::-webkit-scrollbar {
  width: 0.7vw;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #3AAFA9;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #25ada7;
}


.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  
    &.show {
      opacity: 1;
    }
  
    .toast {
      background-color: #333;
      color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      max-width: 300px;
      width: 100%;
  
      .toast-message {
        flex: 1;
      }
    }
  }
  